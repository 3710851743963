import Vue from 'vue';
import Vuetify, {VBtn, VApp, VMain, VSelect} from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        VBtn, VApp, VMain, VSelect
    }
})

export default new Vuetify();
    