// 自定义事件埋点
export function send_record(eventCode, eventParam={}) {
  const {aplus_queue} = window;
  aplus_queue.push({
    action: 'aplus.record',
    arguments: [eventCode, 'CLK', eventParam]
  });
}
// 埋点应用内uid设置
export function set_uid(uid) {
  const {aplus_queue} = window;
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: ["_user_id", uid]
  });
}
// 埋点应用内阻塞上报
export function set_block() {
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: ['_hold', 'BLOCK'] 
   });
}
// 埋点应用内开始上报
export function set_start() {
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: ['_hold', 'START'] 
   });
}