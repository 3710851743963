import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {

  downLoadNumber:-1, // 有免费下载mp3的额度
  userGrade: -1, // 0仅登录 1普通vip-创作者 2高级vip-专业创作者
  // downLoadNumber: 0, // 有免费下载mp3的额度
  // userGrade: 1, // 0仅登录 1普通vip-创作者 2高级vip-专业创作者
  creatorArr: [null, null, null],
  creatorMajorArr: [null, null, null],
  singleMusic: null
}
// this.$store.state.wavesurferAdr

const getters = { //监听
  changeDownNum(state) {
    return state.downLoadNumber;
  },
  changeUserGrade(state) {
    return state.userGrade;
  },

  changeCreatorArr(state) {
    return state.creatorArr;
  },
  changeMajorCreatorArr(state) {
    return state.creatorMajorArr;
  },
  changeCreatorArr(state) {
    return state.singleMusic;
  }

}

const mutations = {
  getDownNum(state, data){
    state.downLoadNumber = data;
  },
  getUserGrade(state, data) {
    state.userGrade = data;
  },

  getCreatorArr(state, data) {
    state.creatorArr = data;
  },
  getMajorCreatorArr(state, data) {
    state.creatorMajorArr = data;
  },
  getSingleMusic(state, data) {
    state.singleMusic = data;
  }
}

// this.$store.dispatch('triggerGetShareData', payload);

const actions = {
  triggerGetDownNum(context, data){
    context.commit("getDownNum", data)
  },
  triggerGetUserGrade(context, data) {
    context.commit("getUserGrade", data)
  },

  triggerGetCreatorArr(context, data){
    context.commit("getCreatorArr", data)
  },
  triggerGetCreatorMajorArr(context, data){
    context.commit("getMajorCreatorArr", data)
  },
  triggerGetSingleMusic(context, data) {
    context.commit("getSingleMusic", data)
  },
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

export default store;

