<template>
  <v-app>
    <v-main>
      <div
        class="login-page"
        v-if="overlayFlag"
        :style="{ top: scrollTop + 'px' }"
      >
        <div class="overlay-container">
          <div class="close-overlay" @click="closeOverlay($event)">×</div>
          <div class="overlay-title">{{ overlayTitle }}</div>
          <!-- 二维码登录 / 注册 -->
          <div
            class="loginByErcode-register funcContainer"
            v-if="loginStep == 0"
          >
            <div class="telPhNum">
              <span>+86</span>
              <span>|</span>
              <input
                v-model="user.telPhNum"
                max-length="11"
                placeholder="请输入手机号"
                pattern="[0-9]*"
                v-number-only
                type="telephone"
                maxlength="11"
              />
              <i
                class="iconfont icon-icon_lable_close_12"
                :style="
                  user.telPhNum.length == 0
                    ? 'display:none;'
                    : 'display: inline-block;'
                "
                @click="clearTelPhNum"
              >
              </i>
            </div>
            <div class="verCode">
              <input
                v-model="user.verCode"
                v-number-only
                placeholder="请输入验证码"
              />
              <span
                @click="onCaptchaShow(1)"
                :style="
                  user.telPhNum.length == 11 && verCodeTip == '获取验证码'
                    ? 'color: #5490F4;cursor:pointer;'
                    : ''
                "
              >
                {{ verCodeTip }}
              </span>
            </div>
            <div
              class="returnErrReason"
              :style="errReasonTip ? 'opacity: 1;' : 'opacity: 0;'"
            >
              {{ errReason }}
            </div>
            <div class="funcContainer-button">
              <v-btn
                class="ma-2 createButton"
                :class="buttonState ? '' : 'cantClick'"
                :loading="loading"
                :disabled="loading"
                color="secondary"
                @click="loginSystem('verCode')"
              >
                一键登录/注册
              </v-btn>
            </div>
            <div class="buttonBottom" @click="jumpLoginStep(1)">密码登录</div>
          </div>
          <!-- 密码登录 -->
          <div class="loginByPwd funcContainer" v-if="loginStep == 1">
            <div class="telPhNum">
              <span>+86</span>
              <span>|</span>
              <input
                v-model="user.telPhNum"
                max-length="11"
                placeholder="请输入手机号"
                pattern="[0-9]*"
                v-number-only
                type="telephone"
                maxlength="11"
              />
              <i
                class="iconfont icon-icon_lable_close_12"
                :style="
                  user.telPhNum.length == 0
                    ? 'display:none;'
                    : 'display: inline-block;'
                "
                @click="clearTelPhNum"
              >
              </i>
            </div>
            <div class="passWord">
              <input
                v-model="user.passWord"
                id="loginPassWord"
                type="password"
                placeholder="请输入密码"
              />
              <span>
                <i
                  v-if="!passwordTip"
                  class="iconfont icon-biyan"
                  @click="changePassWordType"
                ></i>
                <i
                  v-if="passwordTip"
                  class="iconfont icon-yanjing"
                  @click="changePassWordType"
                ></i>
                <i
                  class="iconfont icon-icon_lable_close_12"
                  :style="
                    user.passWord.length == 0 ? 'opacity: 0;' : 'opacity: 1;'
                  "
                  @click="clearPassword"
                ></i>
              </span>
            </div>
            <div
              class="returnErrReason"
              :style="errReasonTip ? 'opacity: 1;' : 'opacity: 0;'"
            >
              {{ errReason }}
            </div>
            <div class="funcContainer-button">
              <v-btn
                class="ma-2 createButton"
                :class="buttonState ? '' : 'cantClick'"
                :loading="loading"
                :disabled="loading"
                color="secondary"
                @click="loginSystem('passWord')"
              >
                登录
              </v-btn>
            </div>
            <div class="buttonBottom">
              <span @click="jumpLoginStep(0)">验证码登录</span>
              <span style="margin: 0 10px 0 4px">|</span>
              <span @click="jumpLoginStep(2)">忘记密码</span>
              <span style="margin: 0 10px 0 10px">|</span>
              <span @click="jumpLoginStep(0)">立即注册</span>
            </div>
          </div>
          <!-- 忘记密码  身份验证-->
          <div class="forgetPwd funcContainer" v-if="loginStep == 2">
            <div class="telPhNum">
              <span>+86</span>
              <span>|</span>
              <input
                v-model="user.telPhNum"
                max-length="11"
                placeholder="请输入手机号"
                pattern="[0-9]*"
                v-number-only
                type="telephone"
                maxlength="11"
              />
              <i
                class="iconfont icon-icon_lable_close_12"
                @click="clearTelPhNum"
              ></i>
            </div>
            <div class="verCode">
              <input
                v-model="user.verCode"
                v-number-only
                placeholder="请输入验证码"
              />
              <span
                @click="onCaptchaShow(2)"
                :style="
                  user.telPhNum.length == 11 && verCodeTip == '获取验证码'
                    ? 'color: #5490F4;cursor:pointer;'
                    : ''
                "
              >
                {{ verCodeTip }}
              </span>
            </div>
            <div
              class="returnErrReason"
              :style="errReasonTip ? 'opacity: 1;' : 'opacity: 0;'"
            >
              {{ errReason }}
            </div>
            <div class="funcContainer-button">
              <v-btn
                class="ma-2 createButton"
                :class="buttonState ? '' : 'cantClick'"
                :loading="loading"
                :disabled="loading"
                color="secondary"
                @click="forgetPassword"
                v-if="overlayTitle == '修改手机号'"
              >
                确认更换
              </v-btn>
              <v-btn
                class="ma-2 createButton"
                :class="buttonState ? '' : 'cantClick'"
                :loading="loading"
                :disabled="loading"
                color="secondary"
                @click="forgetPassword"
                v-else
              >
                下一步
              </v-btn>
            </div>
            <div class="buttonBottom">
              <span
                v-if="setPwdFrom == 'loginForgetPwd'"
                @click="jumpLoginStep(1)"
                >返回</span
              >
              <span
                v-if="
                  setPwdFrom.indexOf('user') > -1 &&
                  overlayTitle != '修改手机号'
                "
                @click="jumpLoginStep(11)"
                >无法接收验证码</span
              >
              <span
                v-if="
                  overlayTitle == '修改手机号' &&
                  setPwdFrom.indexOf('user') > -1
                "
                @click="closeOverlay($event)"
              >
                取消
              </span>
            </div>
          </div>
          <!-- 忘记密码->修改密码 首次注册->设置密码 个人中心->设置密码 个人中心->修改密码 -->
          <div class="setPwd funcContainer" v-if="loginStep == 3">
            <div class="setPassWord">
              <input
                v-model="user.passWord"
                type="password"
                id="loginPassWord"
                placeholder="请设置新密码"
              />
              <span>
                <i
                  v-if="!passwordTip"
                  class="iconfont icon-biyan"
                  @click="changePassWordType"
                ></i>
                <i
                  v-if="passwordTip"
                  class="iconfont icon-yanjing"
                  @click="changePassWordType"
                ></i>
                <i
                  class="iconfont icon-icon_lable_close_12"
                  :style="
                    user.passWord.length == 0 ? 'opacity: 0;' : 'opacity: 1;'
                  "
                  @click="clearPassword"
                ></i>
              </span>
            </div>
            <div class="password-grade">
              <div
                :style="
                  strengthPwd || user.passWord.length == 0
                    ? ' color: #B8B8B8;'
                    : ' color: red;'
                "
              >
                6-16位数字字母混合密码
              </div>
            </div>
            <div class="funcContainer-button" style="margin-top: 127px">
              <v-btn
                class="ma-2 createButton"
                :class="buttonState ? '' : 'cantClick'"
                :loading="loading"
                :disabled="loading"
                color="secondary"
                @click="submitPassWord"
              >
                提交
              </v-btn>
            </div>
            <div class="buttonBottom">
              <span
                v-if="setPwdFrom == 'loginForgetPwd'"
                @click="jumpLoginStep(2)"
                >返回</span
              >
              <span
                v-if="setPwdFrom == 'loginSetPwd'"
                @click="closeOverlay($event)"
                >跳过</span
              >
              <span
                v-if="setPwdFrom.indexOf('user') > -1"
                @click="closeOverlay($event)"
                >取消</span
              >
            </div>
          </div>
          <!-- 密码设置成功 -->
          <div class="setPassWord-success" v-if="loginStep == 4">
            <div class="password-tip">恭喜你设置成功!</div>
            <div class="success-img">
              <img src="./assets/paymentCenter/paymentSuccess.png" />
            </div>
            <div
              v-if="setPwdFrom == 'loginForgetPwd'"
              class="setPwdSuccess"
              @click="setSuccLoginSystem"
            >
              完成并登录
            </div>
            <div
              v-if="setPwdFrom == 'userSetPwd'"
              class="setPwdSuccess"
              @click="closeOverlay($event)"
            >
              完成
            </div>
          </div>
          <!-- 是否退出登录 -->
          <div class="logOutSystem" v-if="loginStep == 5">
            <div class="logOutSystem-title">是否退出登录？</div>
            <div class="logOutSystem-button">
              <span @click="determineLogOutSystem">确认</span>
              <span @click="closeOverlay($event)">取消</span>
            </div>
          </div>
          <!-- 联系客服 -->
          <div class="contact-customerSrvice" v-if="loginStep == 11">
            <div class="customer">
              <img src="./assets/customerService.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-left">
        <div class="left-logo" @click="jumpRouter(0)">
          <img src="./assets/bgmlogo.png" />
        </div>
        <div class="left-router">
          <p class="router-home" @click="jumpRouter(0)">
            <i
              v-if="tabIndex != 0"
              class="iconfont icon-icon_leftbar_home_unselect_241"
            ></i>
            <i
              v-if="tabIndex == 0"
              style="color: #5490f4"
              class="iconfont icon-icon_leftbar_home_select_241"
            ></i>
          </p>
          <p class="router-collection" @click="jumpRouter(1)">
            <i
              v-if="tabIndex != 1"
              class="iconfont icon-icon_leftbar_collect_unselect_241"
            ></i>
            <i
              v-if="tabIndex == 1"
              style="color: #5490f4"
              class="iconfont icon-icon_leftbar_collect_select_241"
            ></i>
          </p>
          <p class="router-feedback" @click="jumpRouter(2)">
            <i
              v-if="tabIndex != 2"
              class="iconfont icon-icon_leftbar_feedback_unselect_241"
            ></i>
            <i
              v-if="tabIndex == 2"
              style="color: #5490f4"
              class="iconfont icon-icon_leftbar_feedback_select_241"
            ></i>
          </p>
        </div>
        <div class="left-user" @click="jumpRouter(3)">
          <i
            class="iconfont icon-xintouxiang"
            style="color: white; font-size: 40px; cursor: pointer"
          ></i>
        </div>
      </div>
      <keep-alive>
        <router-view
          :class="overlayFlag ? 'No_scrolling' : ''"
          v-if="$route.meta.keepAlive"
          ref="child"
          @collectionLoginSystm="collectionLoginSystm"
          @userLogOutSystem="userLogOutSystem"
          @userChangePwd="userChangePwd"
          @userChangeTel="userChangeTel"
        >
        </router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive"
        ref="child"
        :class="overlayFlag ? 'No_scrolling' : ''"
      >
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import qs from "qs";
import { set_uid, send_record, set_block, set_start } from "./utils/umeng_util";
import { axiosGet, axiosPost } from "../src/axios.js";
import {
  LOGINREG,
  GETVERCODE,
  CHANGEINFORMATION,
  FORGETPWD,
  CHANGTELGETVERCODE,
  CHANGEUSERPHONE,
  FORGETPWDGETVERCODE,
  GETUSERINFORMATION,
  VIPLIST,
} from "../src/api.js";
export default {
  name: "App",
  data: () => ({
    scrollTop: 0,
    tabIndex: 0, // 页面下标

    overlayFlag: false, // 遮罩层是否显示

    buttonState: false,

    errReasonTip: false,
    errReason: "这里是错误原因",

    loading: false, // 注册按钮的加载状态

    loginStep: 0, // 登录步骤  0注册登录页面 1密码登录页面
    overlayTitle: "登录", //标题

    passwordTip: false, // 密码登录是否显示密码 false不显示 true显示
    strengthPwd: false, // 密码强度
    user: {
      telPhNum: "", // 手机号
      verCode: "", // 验证码
      passWord: "", // 密码
      usedTelNum: "", // 要替换的手机号
    },
    setPwdFrom: "", // 设置密码怎么过来的
    // loginSetPwd loginForgetPwd userChangePwd userSetPwd userChangeTel

    needPassword: -1, // 登录&注册 0登录  1注册

    timer: null, // 验证码倒计时计时器
    verCodeTips: true, // 是否可以获取验证码
    countDown: 60, // 获取验证码倒计时
    verCodeTip: "获取验证码",
    aidEncrypted: "199201695",
    captcha: null,
    verCodeChannel: 1,
    ticketInfo: {},
  }),
  directives: {
    numberOnly: {
      bind: function (e) {
        e.handler = function () {
          e.value = e.value.replace(/\D+/, "");
        };
        e.addEventListener("input", e.handler);
      },
      unbind: function (e) {
        e.removeEventListener("input", e.handler);
      },
    },
  },
  watch: {
    $route(to, from) {
      this.comfireTabIndex();
    },
    user: {
      handler(val, oldVal) {
        if (val.telPhNum[0] != 1) {
          this.user.telPhNum = "";
        }
        if (val.verCode.length > 6) {
          this.user.verCode = val.verCode.substring(0, 6);
        }
        if (val.passWord.length > 16) {
          this.user.passWord = val.passWord.substring(0, 16);
        }
        let reg = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z]).{6,16}");
        if (reg.test(val.passWord)) {
          this.strengthPwd = true;
        } else {
          this.strengthPwd = false;
        }

        if (this.loginStep == 0 || this.loginStep == 2) {
          if (val.telPhNum.length == 11 && val.verCode.length == 6) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        } else if (this.loginStep == 1) {
          if (val.telPhNum.length == 11 && val.passWord.length > 6) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        } else if (this.loginStep == 3) {
          if (this.strengthPwd) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        }
      },
      deep: true,
    },
    loginStep: function (val, oldVal) {
      if (val == 0 || val == 2) {
        if (this.user.telPhNum.length == 11 && this.user.verCode.length == 6) {
          this.buttonState = true;
        } else {
          this.buttonState = false;
        }
      } else if (val == 1) {
        if (this.user.telPhNum.length == 11 && this.user.passWord.length > 6) {
          this.buttonState = true;
        } else {
          this.buttonState = false;
        }
      } else if (val == 3) {
        if (this.strengthPwd) {
          this.buttonState = true;
        } else {
          this.buttonState = false;
        }
      }
    },
    overlayFlag: function (val, oldVal) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "scroll";
      }
    },
  },
  beforeMount() {
    this.setBdVid();
    if (
      localStorage.getItem("token") == null &&
      window.location.href.indexOf("user") > -1
    ) {
      this.$router.push({ path: "/home" });
    }
    if (localStorage.getItem("downloadMusicID") != null) {
      localStorage.removeItem("downloadMusicID");
    }
  },
  mounted() {
    localStorage.removeItem("cancelFavoriteList");
    localStorage.removeItem("templateId");
    this.comfireTabIndex();
    window.addEventListener("scroll", this.getScrollTop, true);
    if (
      localStorage.getItem("token") != null &&
      this.$store.state.userGrade == -1
    ) {
      this.getUserGradeInf();
    }
  },
  methods: {
    setCookie(name, value) {
      var Days = 1;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie = name + "=" + value + ";expires=" + exp.toGMTString();
    },
    delCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    },
    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },

    setBdVid() {
      let locationSearchArr = window.location.search.slice(1).split("&");
      for (let i = 0; i < locationSearchArr.length; i++) {
        if (locationSearchArr[i].indexOf("bd_vid") > -1) {
          localStorage.setItem("bd_vid", locationSearchArr[i].slice(7));
          break;
        } else {
          localStorage.removeItem("bd_vid");
        }
      }
    },
    getUserGradeInf() {
      axiosGet(GETUSERINFORMATION)
        .then((res) => {
          let data = res.data;
          set_uid(data.uid);
          set_start();
          this.$store.dispatch("triggerGetUserGrade", data.vip);
          this.$store.dispatch(
            "triggerGetDownNum",
            data["download_month_residue"]
          );
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
          if (err.data.code == -1) {
            // TODO:此时应该弹出登录
          }
        });
      axiosGet(VIPLIST)
        .then((res) => {
          let data = res.data;
          let creatorMajorArr = [null, null, null];
          let creatorArr = [null, null, null];
          let singleMusic = null;
          for (let i = 0; i < data.length; i++) {
            if (data[i].goods_identifier == "professional_month") {
              creatorMajorArr[0] = data[i];
            } else if (data[i].goods_identifier == "professional_quarter") {
              creatorMajorArr[1] = data[i];
            } else if (data[i].goods_identifier == "professional_year") {
              creatorMajorArr[2] = data[i];
            } else if (data[i].goods_identifier == "creator_month") {
              creatorArr[0] = data[i];
            } else if (data[i].goods_identifier == "creator_quarter") {
              creatorArr[1] = data[i];
            } else if (data[i].goods_identifier == "creator_year") {
              creatorArr[2] = data[i];
            } else if (data[i].goods_identifier == "single_music") {
              singleMusic = data[i];
            }
          }
          this.$store.dispatch("triggerGetCreatorArr", creatorArr);
          this.$store.dispatch("triggerGetCreatorMajorArr", creatorMajorArr);
          this.$store.dispatch("triggerGetSingleMusic", singleMusic);
        })
        .catch((err) => {
          console.log("getVipList error", err);
        });
    },
    // 验证码登录获取验证码 or 首次注册获取验证码  1
    getVerCode() {
      if (this.verCodeTips) {
        this.verCodeTip = this.countDown + "秒后重新获取";
        this.timer = setInterval(() => {
          this.countDown--;
          this.verCodeTip = this.countDown + "秒后重新获取";
          if (this.countDown == -1) {
            this.setAllTimerDataBegin();
          }
        }, 1000);
      } else {
        return;
      }
      if (this.verCodeTips) {
        this.verCodeTips = false;
        axiosPost(
          GETVERCODE,
          qs.stringify({ phone: this.user.telPhNum, ...this.ticketInfo })
        )
          .then((res) => {
            // console.log("获取验证码成功", res);
          })
          .catch((err) => {
            console.log("获取验证码失败 ", err);
          });
      }
    },
    // 登录系统 验证码登录 or 密码登录
    loginSystem(str) {
      if (!this.buttonState) {
        return;
      }
      let transmitData;
      if (str == "verCode") {
        transmitData = {
          phone: this.user.telPhNum,
          verify: this.user.verCode,
        };
      } else {
        transmitData = {
          phone: this.user.telPhNum,
          pwd: this.user.passWord,
        };
      }
      this.loading = true;
      this.buttonState = false;
      axiosPost(LOGINREG, qs.stringify(transmitData))
        .then((res) => {
          this.errReasonTip = false;
          this.loading = false;
          this.buttonState = true;
          this.needPassword = res.data.needPassword;
          localStorage.setItem("token", res.data.token);
          if (window.location.href.indexOf("/collection") > -1) {
            this.$refs.child.judgePageShow();
          } else if (window.location.href.indexOf("/home") > -1) {
            this.$refs.child.getCreateAudioNumNoLogin();
          }
          this.user.verCode = "";
          this.getUserGradeInf();
          set_block();
          if (res.data.isReg) {
            send_record("user_register");
          } else {
            str == "verCode"
              ? send_record("user_login", { type: "手机号登录" })
              : send_record("user_login", { type: "密码登录" });
          }
          // 注册登录
          if (res.data.needPassword) {
            // 注册or注册时未设置密码 跳转到设置密码界面
            this.loginStep = 3;
            this.overlayTitle = "设置密码";
            this.setPwdFrom = "loginSetPwd";
          } else {
            // 直接登录成功
            this.overlayFlag = false;
            this.user.telPhNum = "";
            this.user.passWord = "";
            if (this.tabIndex == 3) {
              this.$router.push({ path: "/user" });
            }
          }
          this.setAllTimerDataBegin();
        })
        .catch((err) => {
          this.loading = false;
          this.buttonState = true;
          if ("data" in err && err.data.code == -1) {
            this.errReasonTip = true;
            this.errReason = err.data.msg;
          } else {
            this.errReasonTip = false;
            this.$message.info(err.data.msg);
          }
        });
    },
    // 忘记密码获取验证码  2
    ForgetPassWordgetVerCode() {
      if (this.verCodeTips) {
        this.verCodeTip = this.countDown + "秒后重新获取";
        this.timer = setInterval(() => {
          this.countDown--;
          this.verCodeTip = this.countDown + "秒后重新获取";
          if (this.countDown == -1) {
            this.setAllTimerDataBegin();
          }
        }, 1000);
      } else {
        return;
      }
      if (this.verCodeTips) {
        this.verCodeTips = false;

        if (this.setPwdFrom == "userChangeTel") {
          axiosPost(
            CHANGTELGETVERCODE,
            qs.stringify({ phone, ...this.ticketInfo })
          )
            .then((res) => {
              // console.log("更换手机号获取验证码成功");
              this.$message.success("已发送");
            })
            .catch((err) => {
              console.log("更换手机号验证码获取失败", err);
            });
        } else {
          axiosPost(
            FORGETPWDGETVERCODE,
            qs.stringify({ phone: this.user.telPhNum, ...this.ticketInfo })
          )
            .then((res) => {
              // console.log("忘记密码 or 设置密码 获取验证码成功", res);
            })
            .catch((err) => {
              console.log("忘记密码 or 设置密码 获取验证码失败 ", err);
            });
        }
      }
    },
    //忘记密码 电话号 && 验证码 获取check
    forgetPassword() {
      if (!this.buttonState) return;

      this.loading = true;
      this.buttonState = false;
      let data;
      if (
        this.setPwdFrom == "loginForgetPwd" ||
        this.setPwdFrom == "userChangePwd" ||
        this.setPwdFrom == "userSetPwd"
      ) {
        // 忘记密码->电话号 && 验证码 获取check
        data = {
          phone: this.user.telPhNum,
          verify: this.user.verCode,
        };
        axiosPost(FORGETPWD, qs.stringify(data))
          .then((res) => {
            this.loading = false;
            this.buttonState = true;
            this.loginStep = 3;
            if (this.setPwdFrom == "userSetPwd") {
              this.overlayTitle = "设置密码";
            } else {
              this.overlayTitle = "修改密码";
            }
            if (res.data.check != null) {
              localStorage.setItem("check", res.data.check);
            }
          })
          .catch((err) => {
            this.loading = false;
            this.buttonState = true;
            this.$message.info(err.data.msg);
          });
      } else if (this.setPwdFrom == "userChangeTel") {
        if (localStorage.getItem("x") == null) {
          data = {
            phone: this.user.telPhNum,
            verify: this.user.verCode,
          };
        } else {
          data = {
            phone: this.user.telPhNum,
            verify: this.user.verCode,
            x: localStorage.getItem("x"),
          };
        }
        axiosPost(CHANGEUSERPHONE, qs.stringify(data))
          .then((res) => {
            this.errReasonTip = false;
            this.loading = false;
            this.buttonState = true;
            if (res.data.x) {
              localStorage.setItem("x", res.data.x);
              this.overlayTitle = "修改手机号";
              this.verCodeTips = true;
              this.verCodeTip = "获取验证码";
              this.countDown = 60;
              clearInterval(this.timer);
            } else {
              send_record("user_change_phone");
              localStorage.removeItem("x");
              this.overlayFlag = false;
              this.$refs.child.getUserInformation();
            }
            this.user.verCode = "";
            this.user.telPhNum = "";
          })
          .catch((err) => {
            this.loading = false;
            this.buttonState = true;
            if (err.data.code == -1) {
              this.errReasonTip = true;
              this.errReason = err.data.msg;
            } else {
              this.errReasonTip = false;
              this.$message.info(err.data.msg);
            }
          });
      }
    },
    // 忘记密码->提交密码 首次注册->提交密码 个人中心修改密码 个人中心设置密码
    submitPassWord() {
      if (!this.strengthPwd) {
        return;
      }
      this.loading = true;
      this.buttonState = false;
      let transmitData;
      // 首次注册->提交密码
      if (this.setPwdFrom == "loginSetPwd") {
        transmitData = {
          phone: this.user.telPhNum,
          new_pwd: this.user.passWord,
        };
        axiosPost(CHANGEINFORMATION, qs.stringify(transmitData))
          .then((res) => {
            send_record("user_set_password");
            this.loading = false;
            this.buttonState = true;
            this.overlayFlag = false;
            if (this.tabIndex == 3) {
              this.$router.push({ path: "/user" });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.buttonState = true;
            this.$message.info(err.data.msg);
          });
      } else if (
        this.setPwdFrom == "loginForgetPwd" ||
        this.setPwdFrom == "userChangePwd" ||
        this.setPwdFrom == "userSetPwd"
      ) {
        // 忘记密码->提交密码
        transmitData = {
          phone: this.user.telPhNum,
          pwd: this.user.passWord,
          check: localStorage.getItem("check"),
        };
        axiosPost(FORGETPWD, qs.stringify(transmitData))
          .then((res) => {
            this.loading = false;
            this.buttonState = true;
            if (this.setPwdFrom == "loginForgetPwd") {
              send_record("user_change_password");
              this.loginStep = 4;
              this.overlayTitle = "设置成功";
            } else if (this.setPwdFrom == "userChangePwd") {
              send_record("user_change_password");
              this.overlayFlag = false;
            } else if (this.setPwdFrom == "userSetPwd") {
              send_record("user_set_password");
              this.loginStep = 4;
              this.overlayTitle = "设置成功";
            }
          })
          .catch((err) => {
            this.loading = false;
            this.buttonState = true;
            if (err.data.code == -1) {
              this.$message.info(err.data.msg);
            } else {
              this.$message.error("未知错误,请重试!");
            }
          });
      }
    },
    // 忘记密码->提交密码->完成并登录
    setSuccLoginSystem() {
      if (this.setPwdFrom == "loginForgetPwd") {
        this.loginSystem("passWord");
      }
    },
    // 用户在个人中心修改密码
    userChangePwd(needPassword, telphone) {
      this.overlayFlag = true;
      if (needPassword == 0) {
        this.setPwdFrom = "userChangePwd";
      } else {
        this.setPwdFrom = "userSetPwd";
      }
      this.overlayTitle = "身份验证";
      this.loginStep = 2;
      this.user.telPhNum = "";
      this.user.verCode = "";
      this.user.passWord = "";
    },
    // 用户在个人中心更换绑定手机号
    userChangeTel(tel) {
      this.overlayFlag = true;
      this.loginStep = 2;
      this.overlayTitle = "身份验证";
      this.setPwdFrom = "userChangeTel";
      this.user.telPhNum = "";
      this.user.verCode = "";
      this.user.passWord = "";
    },
    // 用户在个人中心要退出
    userLogOutSystem() {
      this.overlayFlag = true;
      this.loginStep = 5;
      this.overlayTitle = null;
      this.user.telPhNum = "";
      this.user.verCode = "";
      this.user.passWord = "";
    },
    // 收藏页面未登录 or 播放器点击收藏未登录调出登录页面
    collectionLoginSystm() {
      this.overlayFlag = true;
      this.loginStep = 0;
      this.overlayTitle = "登录";
      this.user.telPhNum = "";
      this.user.verCode = "";
      this.user.passWord = "";
    },
    // 确认退出登录
    determineLogOutSystem() {
      send_record("user_logout");
      localStorage.removeItem("token");
      this.$router.push({ path: "/" });
      this.loginStep = 0;
      this.user.telPhNum = "";
      this.user.verCode = "";
      this.user.passWord = "";
      this.overlayFlag = false;
    },
    // timer有关的数据恢复初始状态
    setAllTimerDataBegin() {
      this.verCodeTips = true;
      this.verCodeTip = "获取验证码";
      this.countDown = 60;
      clearInterval(this.timer);
      this.timer = null;
    },
    jumpLoginStep(index) {
      /**
       * index == 1 跳转到密码登录
       * index == 2 跳转到忘记密码
       * index == 11 联系客服
       */
      if (index == 0 || index == 1) {
        this.loginStep = index;
        this.overlayTitle = "登录";
      } else if (index == 2) {
        this.loginStep = 2;
        this.overlayTitle = "忘记密码";
        this.setPwdFrom = "loginForgetPwd";
      } else if (index == 11) {
        this.loginStep = 11;
        this.overlayTitle = "联系客服";
      }
    },
    // 清除手机号
    clearTelPhNum() {
      this.user.telPhNum = "";
    },
    // 清除密码
    clearPassword() {
      this.user.passWord = "";
    },
    // 关闭登录弹窗
    closeOverlay(e) {
      this.overlayFlag = false;
      if (e.target.innerText == "跳过") {
        this.user.telPhNum = "";
        if (this.tabIndex == 3) {
          this.$router.push({ path: "/user" });
        }
      } else if (e.target.innerText == "完成") {
        this.$refs.child.getUserInformation();
      }
      if (this.timer != null) {
        this.setAllTimerDataBegin();
      }
      if (localStorage.getItem("x") != null) {
        localStorage.removeItem("x");
      }

      this.user.telPhNum = "";
      this.user.verCode = "";
      this.user.passWord = "";
    },
    // 修改密码输入框的类型 密码是否可见
    changePassWordType() {
      this.passwordTip = !this.passwordTip;
      if (this.passwordTip) {
        document.getElementById("loginPassWord").type = "text";
      } else {
        document.getElementById("loginPassWord").type = "password";
      }
    },
    // 点击路由跳转
    jumpRouter(index) {
      this.comfireTabIndex();
      if (this.tabIndex == 0 || this.tabIndex == 1) {
        this.$refs.child.destoryWave();
      } else if (this.tabIndex == 3) {
        this.$refs.child.pauseAudio();
      }
      this.tabIndex = index;
      if (this.tabIndex == 0) {
        if (this.$route.name === "Home") {
          return;
        }
        this.$router.push({ path: "/" });
      } else if (this.tabIndex == 1) {
        this.$router.push({ path: "/collection" });
      } else if (this.tabIndex == 2) {
        this.$router.push({ path: "/feedback" });
      } else if (this.tabIndex == 3) {
        if (localStorage.getItem("token") == null) {
          this.overlayFlag = true;
          this.loginStep = 0;
          this.overlayTitle = "登录";
        } else if (localStorage.getItem("token") != null) {
          this.$router.push({ path: "/user" });
        }
      }
    },
    // 路由相关
    comfireTabIndex() {
      let href = window.location.href;
      if (href.indexOf("/home") > -1) {
        this.tabIndex = 0;
      } else if (href.indexOf("/collection") > -1) {
        this.tabIndex = 1;
      } else if (href.indexOf("/feedback") > -1) {
        this.tabIndex = 2;
      } else if (href.indexOf("/user") > -1) {
        this.tabIndex = 3;
      }
    },
    getScrollTop() {
      this.scrollTop = document.documentElement.scrollTop;
    },
    // 验证回调结果
    callback(res) {
      // console.log("callback:", res);
      const { ret, ticket, randstr } = res;
      this.ticketInfo = {
        ticket,
        randstr,
      };
      if (ret === 0) {
        this.verCodeChannel === 2
          ? this.ForgetPassWordgetVerCode()
          : this.getVerCode();
      }
    },
    // 错误处理函数
    loadErrorCallback() {
      // 生成容灾票据或自行做其它处理
      var ticket =
        "trerror_1001_" +
        this.aidEncrypted +
        "_" +
        Math.floor(new Date().getTime() / 1000);
      this.callback({
        ret: 0,
        randstr: "@" + Math.random().toString(36).substr(2),
        ticket: ticket,
        errorCode: 1001,
        errorMessage: "jsload_error",
      });
    },
    // 调用验证码
    /**
     *
     * @param type 1 - 登录/注册  2 - 忘记密码
     */
    onCaptchaShow(type) {
      if (this.user.telPhNum.length < 11) {
        return;
      }
      if (!this.verCodeTips) {
        return;
      }
      this.verCodeChannel = type;
      try {
        if (!this.captcha) {
          this.captcha = new TencentCaptcha(
            this.aidEncrypted,
            this.callback,
            {}
          );
        }
        this.captcha.show();
      } catch (error) {
        console.log(error);
        // 加载异常，调用验证码js加载错误处理函数
        this.loadErrorCallback();
      }
    },
    // 获取验证码AppID
    getEncryptAppid() {},
  },
};
</script>
<style>
@import "./common/font/font.css";
.v-application--wrap {
  background: #f3f3f3;
  min-width: 1440px;
  min-height: 900px !important;
  height: 100vh !important;
}
.v-main__wrap {
  display: flex;
}
/* 最小高度900px */
/* .container-left{
  min-height: 900px !important;
  height: 100vh !important;
  min-width: 88px;
  background: #fff;
  background: #000;
  text-align: center;
} */
.container-left {
  position: fixed;
  top: 0;
  z-index: 2;
  min-height: 550px !important;
  height: 100vh !important;
  min-width: 88px;
  background: #000;
  text-align: center;
}
.left-logo {
  margin-top: 83px !important;
}
.left-logo img {
  width: 48px;
  height: 48px;
  pointer-events: none;
  cursor: pointer;
}
.left-router {
  margin-top: 75px !important;
}
.left-router p {
  margin-top: 54px !important;
  padding: 0;
  line-height: 1em;
  cursor: pointer;
}
.left-router p i {
  font-size: 20px;
  color: #fff;
}
.left-user {
  position: absolute;
  bottom: 52px;
  width: 88px;
  text-align: center;
}
.left-user img {
  cursor: pointer;
  margin-top: 2px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  -webkit-user-drag: none;
}
</style>

<style scoped lang="scss">
.update-information {
  width: 100vw;
  min-width: 1280px;
  height: 100vh;
  min-height: 900px;
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  .overlay-background {
    width: 100vw;
    min-width: 1280px;
    height: 100vh;
    // min-height: 800px;
    min-height: 900px;
    background: #000;
    opacity: 0.5;
  }
  .information-container {
    text-align: center;
    width: 450px;
    height: 527px;
    background: #ffffff;
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px 26px;
    .close-overlay {
      height: 14px;
      font-size: 28px;
      font-weight: 300;
      color: #aaafbc;
      position: absolute;
      top: 0px;
      right: 15px;
      cursor: pointer;
    }
    img {
      width: 48px;
      height: 48px;
    }
    .inforTitle {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.128571px;
      color: #333333;
      margin-top: 6px;
      margin-bottom: 20px;
    }
    .inforContent {
      padding: 0 11px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: justify;
      letter-spacing: 0.025em;
      color: rgba(0, 0, 0, 0.7);
      span {
        color: #5490f4;
      }
      margin-bottom: 6px;
    }
    .inforContent1 {
      margin: 6px 0;
    }
    .confirm {
      cursor: pointer;
      margin: 20px 88px 0;
      width: 222px;
      height: 44px;
      background: #000000;
      border-radius: 20px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #ffffff;
    }
  }
}

.login-page {
  width: 100vw;
  min-width: 1440px;
  height: 100vh;
  min-height: 900px;
  z-index: 3;
  z-index: 999 !important;
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  .overlay-container {
    width: 304px;
    height: 380px;
    background: #ffffff;
    opacity: 1;
    border-radius: 12px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .close-overlay {
      width: 24px;
      height: 24px;
      line-height: 20px;
      font-size: 30px;
      text-align: center;
      font-weight: 300;
      color: #aaafbc;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .overlay-title {
      margin-top: 34px;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.128571px;
      color: #333333;
    }
    .funcContainer {
      padding: 0 28px;
      margin-top: 40px;
      .telPhNum {
        border-bottom: 1px solid #e5e5e5;
        span:nth-child(1) {
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.0857143px;
          color: #000000;
        }
        span:nth-child(2) {
          margin: 0 7px 0 7px;
          color: #e5e5e5;
        }
        input {
          border: none;
          outline: none;
          font-weight: 500;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 128.5%;
          align-items: center;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.8);
        }
        input::-webkit-input-placeholder {
          color: #b8b8b8;
        }
        i {
          cursor: pointer;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .verCode {
        border-bottom: 1px solid #e5e5e5;
        margin-top: 32px;
        input {
          border: none;
          outline: none;
          width: 128px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 128.5%;
          align-items: center;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.8);
        }
        input::-webkit-input-placeholder {
          color: #b8b8b8;
        }
        span {
          float: right;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: right;
          letter-spacing: 0.0857143px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
      .passWord {
        border-bottom: 1px solid #e5e5e5;
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        input {
          border: none;
          outline: none;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 128.5%;
          display: flex;
          align-items: center;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.8);
        }
        input::-webkit-input-placeholder {
          color: #b8b8b8;
        }
        span {
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.0857143px;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
          i:nth-child(2) {
            font-size: 13px;
            margin-left: 5px;
          }
        }
      }
      .setPassWord {
        // padding-bottom: 58px;
        border-bottom: 1px solid #e5e5e5;
        input {
          border: none;
          outline: none;
          font-weight: 500;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 128.5%;
          letter-spacing: 0.025em;
          color: rgba(0, 0, 0, 0.8);
        }
        input::-webkit-input-placeholder {
          color: #b8b8b8;
        }
        span {
          float: right;
        }
        i {
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }
        i:nth-child(2) {
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .returnErrReason {
        margin-top: 4px;
        height: 16px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 131%;
        letter-spacing: 0.025em;
        color: #d36666;
      }
      .funcContainer-button {
        width: 240px;
        height: 44px;
        overflow: hidden;
        margin-left: 4px !important;
        margin-top: 69px;
        .createButton {
          width: 240px;
          height: 44px;
          overflow: hidden;
          margin-left: 0px !important;
          font-size: 20px;
          background: #000 !important;
          border-radius: 20px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          padding: 0 !important;
          margin: 0 !important;
        }
        .cantClick {
          color: #ffffff;
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
      .buttonBottom {
        cursor: pointer;
        margin-top: 18px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 131%;
        text-align: center;
        letter-spacing: 0.025em;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .setPassWord-success {
      padding: 0 28px;
      .password-tip {
        margin-top: 49px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #000000;
        opacity: 0.8;
      }
      .success-img {
        margin-left: 22px;
        margin-top: 16px;
        img {
          width: 204px;
          height: 53px;
        }
      }
      .setPwdSuccess {
        width: 240px;
        margin-top: 68px;
        height: 44px;
        background: #000000;
        border-radius: 20px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .logOutSystem {
      .logOutSystem-title {
        margin-top: 96px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.128571px;
        color: #333333;
      }
      .logOutSystem-button {
        margin-left: 41px;
        margin-top: 150px;
        span {
          display: inline-block;
          width: 107px;
          height: 44px;
          background: #000000;
          border-radius: 20px;
          color: #fff;
          text-align: center;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 44px;
          letter-spacing: 0.1px;
          color: #ffffff;
          margin-right: 8px;
          cursor: pointer;
        }
        span:nth-child(2) {
          border: 1px solid #000;
          background: #fff;
          color: #000;
        }
      }
    }
    .contact-customerSrvice {
      > div {
        img {
          margin: 72px 93px;
          width: 118px;
          height: 118px;
        }
      }
    }
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style>
.v-snack__wrapper {
  min-width: 200px !important;
}
.v-snack__content {
  padding: 0 0 0 8px !important;
  text-align: center !important;
}
</style>
