export const DEBUG = process.env.NODE_ENV === "production" ? false : true;
let localHost, localDownloadHost;
localHost = process.env.VUE_APP_BASE_API;
localDownloadHost = "https://download.lazycomposer.com/";
export const host = localHost;

export const downloadHost = localDownloadHost;

export const GETVERCODE = host + "/ai_web/sms/login"; // 登录注册获取验证码接口

export const LOGINREG = host + "/ai_web/user/login"; // 注册登录接口

export const CHANGEINFORMATION = host + "/ai_web/user/updateInfo"; // 个人信息修改

export const FAVORITELIST = host + "/ai_web/music/favorite_list"; // 获取收藏列表

export const GETUSERINFORMATION = host + "/ai_web/user/getUserInfo"; // 用户信息获取

export const CHANGTELGETVERCODE = host + "/ai_web/sms/changePhone"; // 修改手机号验证码获取

export const CHANGEUSERPHONE = host + "/ai_web/user/changeUserPhone"; // 修改手机号

export const FAVORITEORNOT = host + "/ai_web/music/favorite"; // 收藏/取消收藏

export const DOWNLOADFILE = host + "/ai_web/mvp/download_file"; // 音频下载

export const FORGETPWDGETVERCODE = host + "/ai_web/sms/forgetPwd"; //忘记密码验证码获取

export const FORGETPWD = host + "/ai_web/user/forgetPwd"; // 忘记密码修改密码

export const LABELARR = host + "/ai_web/mvp/get_label"; // 获取标签

export const FILEINFO = host + "/ai_web/mvp/fileInfo"; // 计算数据初始化

export const SOUNDTRACK = host + "/ai_web/mvp/soundtracks"; // 生成歌曲

export const ADD = host + "/ai_web/mvp/feedback/add"; // 发送反馈

export const POINT = host + "/ai_web/point"; // 播放次数埋点

export const VIPLIST = host + "/ai_web/goods"; //获取会员列表

export const GENERATEORDER = host + "/ai_web/payment/order"; //生成订单

export const QUERYORDERSTATUS = host + "/ai_web/payment/order/success"; //查询订单状态

export const QUERYPAYMENTHISTORY = host + "/ai_web/payment/purchase"; // 查询购买历史

export const GETLICENSEGEN = host + "/ai_web/payment/license/gen"; //生成授权书

export const UPDATEMUSICINFORMATION = host + "/ai_web/music"; //歌曲信息更新 设置备注
