import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("../components/home.vue");
const Collection = () => import("../components/collection.vue");
const Feedback = () => import("../components/feedback.vue");
const User = () => import("../components/user.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/collection",
    name: "Collection",
    component: Collection,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
