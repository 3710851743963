import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';



import { Tabs, Drawer, Select, Icon, Row, Col, Slider, message  } from 'ant-design-vue'

import 'ant-design-vue/dist/antd.css'
import './common/font/font.css'
import './common/iconfont/iconfont.css'
import './common/iconfont2/iconfont.css'

Vue.config.productionTip = false
// Vue.use(Antd);
Vue.use(Tabs)
Vue.use(Drawer)
Vue.use(Select)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Slider)
Vue.prototype.$message = message;
message.config({
    duration: 1,// 持续时间
    top:`90vh`, // 到页面顶部距离
    maxCount: 1 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});
new Vue({
  router,
  store,
  vuetify,
  // VBtn, VApp, VMain, VSelect ,
  render: h => h(App)
}).$mount('#app')


// Sentry.init({
//   dsn: 'http://96ab169e116e4feba9febc01c9cff50b@192.168.2.148:9000/11',
//   integrations: [new Integrations.Vue({Vue, attachProps: true})],
// });