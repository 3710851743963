import axios from 'axios';
import Vue from 'vue'

//http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        }
        if(localStorage.getItem("token") != null) {
            config.headers.token = localStorage.getItem("token");
        }
        if(localStorage.getItem("bd_vid") != null) {
            config.headers.bd_vid = localStorage.getItem("bd_vid");
        }
        // console.log(config)
        return config;
    },
    error => {
      Vue.prototype.$message.error('网络连接异常,请检查网络')
      return Promise.error(error);
    }
  );

  //http response 拦截器
axios.interceptors.response.use(
    response => {
      if (response.status !== 200 || !('data' in response)) {
        if(!('msg' in response.data)){
          Vue.prototype.$message.error('网络错误,请检查网络后重试')
        }
        return Promise.reject(response);  
      }
      try{
        if (!('msg' in response.data)){
          return Promise.resolve(response);   
        }
      }
      catch{
        return Promise.resolve(response);   
      }
      if (response.data.code == 0 ) {
          return Promise.resolve(response);        
      } else if (response.data.code == -1 ){
          Vue.prototype.$message.error(response.data.msg)
          return Promise.reject(response);
      }else{
          return Promise.reject(response);
      }
    },
    error => {
      Vue.prototype.$message.error('网络错误,请检查网络后重试')
      return Promise.reject(error)
    }
  )
  

  export function axiosGet(url,params={}){
    return new Promise((resolve,reject) => {
      axios.get(url,{
        params:params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
    })
  }



 export function axiosPost(url,params = {}){
    return new Promise((resolve,reject) => {
      axios.post(url,params)
           .then(response => {
             resolve(response.data);
           },err => {
             reject(err)
           })
    })
  }


